import { createAction } from "redux-actions";

/* Storage actions */
const storeEditorialChannels = createAction("STORE_EDITORIAL_CHANNELS");
const storeEditorialChannelsFilters = createAction("STORE_EDITORIAL_CHANNELS_FILTERS");
const addEditorialChannel = createAction("ADD_EDITORIAL_CHANNEL");
const modifyEditorialChannel = createAction("MODIFY_EDITORIAL_CHANNEL");
export const modifyManyEditorialChannels = createAction("MODIFY_MANY_EDITORIAL_CHANNELS");

/* API actions */
export const indexEditorialChannels = createAction("API_INDEX_EDITORIAL_CHANNELS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/channels",
  data,
  fullLoad,
  onSuccessAction: storeEditorialChannels
}));

export const indexEditorialChannelsFilters = createAction("API_INDEX_EDITORIAL_CHANNELS_FILTERS", (data, fullLoad = false) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: "/channels/filters",
  data,
  fullLoad,
  onSuccessAction: storeEditorialChannelsFilters
}));

export const createEditorialChannelLogo = createAction("API_UPDATE_EDITORIAL_CHANNEL_LOGO", (editorialChannelId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "POST",
  url: `/channels/${editorialChannelId}/logos`,
  data,
  headers: {
    "Content-Type": "multipart/form-data"
  },
  onSuccessAction: createAction("STORE_EDITORIAL_CHANNEL_LOGO", result => ({ id: editorialChannelId, result: result.result }))
}));

export const removeEditorialChannelLogo = createAction("API_UPDATE_EDITORIAL_CHANNEL_LOGO", (editorialChannelId, logoId) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/channels/${editorialChannelId}/logos/${logoId}`,
  onSuccessAction: createAction("REMOVE_EDITORIAL_CHANNEL_LOGO", () => ({ id: editorialChannelId, logoId }))
}));

export const storeEditorialChannelById = createAction("API_GET_EDITORIAL_CHANNEL", editorialChannelId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  url: `/channels/${editorialChannelId}`,
  onSuccessAction: modifyEditorialChannel
}));

export const createEditorialChannel = createAction("API_CREATE_EDITORIAL_CHANNEL", data => {
  const onSuccessActions = [
    args => addEditorialChannel(args)
  ];

  data.logos.forEach(logoData => {
    onSuccessActions.push(editorialChannelResult => createEditorialChannelLogo(editorialChannelResult.result.id, logoData));
  });

  onSuccessActions.push(editorialChannelResult => storeEditorialChannelById(editorialChannelResult.result.id));

  return {
    baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
    method: "POST",
    url: "/channels",
    data: data.editorialChannel,
    onSuccessActions
  };
});

export const updateEditorialChannel = createAction("API_UPDATE_EDITORIAL_CHANNEL", (editorialChannelId, data) => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "PUT",
  url: `/channels/${editorialChannelId}`,
  data,
  onSuccessAction: modifyEditorialChannel
}));

export const deleteEditorialChannel = createAction("API_DELETE_EDITORIAL_CHANNEL", editorialChannelId => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "DELETE",
  url: `/channels/${editorialChannelId}`,
  onSuccessAction: createAction("REMOVE_EDITORIAL_CHANNEL", () => ({ id: editorialChannelId }))
}));

export const exportEditorialChannels = createAction("API_EXPORT_EDITORIAL_CHANNELS", () => ({
  baseURL: process.env.REACT_APP_SATTV_ENDPOINT,
  method: "GET",
  url: "/exports/editorial_channels.csv"
}));
